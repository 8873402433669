.privacyButton {
    background-color: var(--color-gray-200);
    border-radius: 6px !important;
    text-transform: none;
    box-shadow: none;
    font-size: 12px;
    color: #000;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    height: 32px;
    padding: 7px 12px;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .privacyButton {
        width: 80vw;
    }
}

.contentWithIcon {
    display: flex;
    align-items: center;
}

.MuiTypography-body1.privacy-text {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: #333;
}

.MuiButton-textSizeMedium.privacyButton:hover {
    background-color: var(--color-gray-300);
}

.help-icon {
    width: 16px;
    height: 16px;
}

.privacy-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.privacyButtonContainer {
    position: relative;
    display: inline-block;
    justify-content: center;
    margin-top: 8px;
}


.warningMessage {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
    transition: visibility 0.2s, opacity 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: var(--color-gray-100);
    border-radius: 6px !important;
    border: 1px solid var(--color-gray-200);
    color: #333;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: 8px;
    white-space: nowrap;
}

.warningMessage.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
}

.warningMessage span {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.warningIcon {
    width: 16px;
    height: 16px;
}

.tooltipHeader {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}

.tooltipDescription {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}