.content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
  color: var(--color-gray-600);
  font-size: medium;
  font-family: sans-serif;
  min-width: 80%;
  margin-left: 10px;
  margin-right: 10px;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  padding-top: 24px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.content-token {
  max-width: 600px;
  margin: auto;
  padding-bottom: 105px;
}

@media only screen and (max-width: 600px) {
  .content-token {
    margin-top: 5vh;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media only screen and (max-height: 700px) {
  .content-container {
    padding-top: 8px;
  }
}

.xfaIcon {
  color: #101319;
  width: auto;
  max-height: 20px;
}

.squaredIcon {
  color: #101319;
  width: auto;
  max-height: 20px;
  margin-bottom: 20px;
}

.success .icon {
  color: #10b981;
  width: 24px;
  height: 24px;
}

.success {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.information {
  text-align: center;
  padding-top: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-gray-600);
}

.general {
  text-align: center;
  padding-top: 6px;
  line-height: 22px;
}

.buttons .extra-information {
  text-align: center;
  padding-bottom: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-gray-600);
}

.buttons .app-not-installed {
  text-align: center;
  padding-bottom: 32px;
  padding-top: 8px;
  width: 80vw;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-gray-600);
}

.success .inline-text {
  display: flex;
  align-items: center;
  align-content: center;
}

.success .inline-text .indicator {
  margin: 2px;
  height: 16px !important;
  width: 16px !important;
}

.container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.MuiTypography-body1.header {
  color: #101319;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 33px;
}

.MuiTypography-body1.body1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 24px;
}

.information .icon {
  color: #919191;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-top: 16px;
  gap: 4px;
}

.general .buttons .skip {
  font-size: smaller;
  margin-top: 12px;
}

.skip-text {
  font-size: 14px !important;
}

.install-text {
  padding: 12px, 24px, 12px, 24px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.refresh-text {
  padding: 12px, 24px, 12px, 24px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.checking {
  margin-top: 15px;
  text-align: center;
}

.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  margin-top: 15px;
  text-align: center;
}

.loading {
  margin-top: 15px;
  text-align: center;
}

.indicator {
  color: var(--color-gray-600) !important;
  height: 24px !important;
  width: 24px !important;
  margin-right: 5px;
}

.error .ErrorIcon {
  color: #d32f2f;
  margin: 15px;
  font-size: 100px !important;
}

.alert {
  margin: 20px;
  width: fit-content;
}

.alertContainer {
  display: flex;
  justify-content: center;
}

.tryAgain {
  text-transform: inherit !important;
}

.smallIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.installationIcon {
  color: #101319;
  width: auto;
  max-height: 32px;
  margin: 4px;
}

.header-with-icon {
  display: flex;
  align-items: center;
}

.header .icon {
  margin-left: 4px;
}

.multiple-explainers {
  display: flex;
  flex-direction: column;
  gap: 8px;
}