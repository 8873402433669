.scrolling-list {
    position: relative;
    display: inline-block;
    text-align: left;
    height: 120px;
}

.list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.list-icon {
    margin-right: 10px;
}