/* BUTTONS */

@media only screen and (max-width: 600px) {
  .MuiButton-root.black-button .MuiButton-root.grey-button {
    width: 80vw !important;
  }

  .MuiButton-root.icon-button {
    width: 80vw !important;
  }
}

.MuiButton-root.black-button {
  width: max-content;
  min-width: 200px;
  height: 43px;
  border-radius: 6px;
  text-transform: inherit !important;
}

.MuiButton-root.black-button:hover {
  background-color: var(--color-gray-800);
  color: #fff;
}

.MuiButton-root.grey-button {
  width: max-content;
  min-width: 200px;
  height: 43px;
  border-radius: 6px;
  text-transform: inherit !important;
  background-color: var(--color-gray-200);
  color: #000;
}

.MuiButton-root.grey-button:hover {
  background-color: var(--color-gray-300);
}

.MuiButton-textSizeMedium.icon-button {
  border: 1px solid var(--color-gray-200);
  border-radius: 6px !important;
  padding: 12px 16px 12px 16px;
  text-transform: none;
  box-shadow: none;
  font-size: 14px;
  color: #000;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-gray-100);
  justify-content: space-between;
  margin-top: 4px;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.skip-button {
  text-transform: inherit !important;
}

.refresh-button {
  text-transform: inherit !important;
}