img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.rotating-icon {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.checking {
  margin-top: 15px;
  text-align: center;
}

.MuiTypography-body1.header {
  color: #101319;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 30px;
}

.MuiTypography-body1.body1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 24px;
}

.MuiTypography-body1.information {
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-gray-600);
}

.rechecking .indicator {
  color: var(--color-gray-600) !important;
  height: 24px !important;
  width: 24px !important;
  margin-right: 5px;
}

.installed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.installed .InstalledIcon {
  margin-right: 2px;
  color: #1ed76e;
  margin: 20px;
  font-size: 100px !important;
}

.openExtension {
  text-transform: inherit !important;
}

.notSupported {
  text-align: center;
}

.notSupported .BrowserNotSupportedIcon {
  margin-right: 2px;
  color: var(--color-gray-700);
  margin: 15px;
  font-size: 100px !important;
}

.notSupported .OSNotSupportedIcon {
  margin-right: 2px;
  color: var(--color-gray-700);
  margin: 15px;
  font-size: 90px !important;
}

.images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  height: 22px;
}

img.image {
  height: 21px;
  margin-right: 16px;
}

img.image-arrows {
  height: 16px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 16px;
}

.notSupported .MobileNotSupportedIcon {
  margin-right: 2px;
  color: var(--color-gray-700);
  margin: 15px;
  font-size: 90px !important;
}

.supported {
  margin-top: 20px;
}

.supportedIcon {
  height: 40px;
}

.androidIcon {
  color: var(--color-gray-900);
  font-size: 40px !important;
}

.supportedItem {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 110px;
}

.supportedItemMobile {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.instructions {
  margin-top: 30px;
  margin-bottom: 60px;
  padding-left: 10px;
}

.MuiButtonBase-root.recheck-button {
  height: 32px;
  padding: 8px 16px;
  gap: 6px;
  border-radius: 32px;
  border: 1px solid var(--color-gray-200);
  background-color: var(--color-gray-100);
  text-transform: inherit !important;
}

.MuiButtonBase-root.recheck-button:hover {
  background-color: var(--color-gray-200);
}

.stepContent {
  margin-top: 10px;
  margin-left: 32px;
  margin-right: 5px;
  min-height: 50px;
  text-align: left;
}

.rechecking {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: right;
  margin-top: 15px;
}

.mobileInstructions {
  text-align: center;
}

.information-container {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 24px;
}

.MuiTypography-body1.privacy-policy {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid var(--color-gray-500);
  color: var(--color-gray-500);
  text-decoration: none;
}

.privacy-policy-container {
  margin-bottom: 32px;
}

.skip-button-container {
  margin-top: 12px;
}