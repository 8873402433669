.updateInstructionsModal {
    width: 520px;
    border-radius: 5px;
    box-shadow: none;
}

.MuiIconButton-sizeMedium.icon-button {
    position: absolute;
    right: 20px;
    top: 20px;
}