.MuiPaper-rounded.encryption-status-alert {
  width: 100%;
  height: 82px;
  padding: 20px 24px 20px 24px;
  border-radius: 6px !important;
  border: 1px solid var(--color-gray-200);
  gap: 8px;
  box-shadow: none !important;
  justify-content: space-between;
  display: flex;
  background-color: var(--color-gray-100) !important;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .MuiPaper-rounded.encryption-status-alert {
    height: 80px;
  }
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.encryption-text-container {
  padding-left: 8px;
}

.encryption-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.MuiPaper-rounded.encryption-status-alert:hover {
  cursor: pointer;
  background-color: var(--color-gray-200) !important;
}

.encryption-status-container:hover {
  cursor: pointer;
}

.MuiTypography-body1.encryption-text {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.MuiButton-containedSizeMedium.encryption-button-warning {
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
  background-color: #FEF3C7 !important;
  color: #B45309 !important;
  text-transform: none;
  box-shadow: none;
  margin-left: 16px;
  box-shadow: none !important;
  text-transform: inherit !important;
  padding: 4px 10px 4px 10px;
  border-radius: 40px !important;
  gap: 8px;
}

.MuiButton-containedSizeMedium.encryption-button-blocking {
  margin-top: auto;
  margin-bottom: auto;
  height: 24px;
  background-color: #fecaca !important;
  color: #991b1b !important;
  text-transform: none;
  box-shadow: none;
  margin-left: 16px;
  box-shadow: none !important;
  text-transform: inherit !important;
  padding: 4px 10px 4px 10px;
  border-radius: 40px !important;
  gap: 8px;
}

.MuiTypography-body1.encryption-button-text {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: right;
  text-transform: inherit !important;
}

.encryption-button-blocking:hover {
  background-color: #ff8787;
  box-shadow: none;
}

.encryption-button-warning:hover {
  background-color: #FEF3C7;
  box-shadow: none;
}

.MuiButton-root {
  border-radius: 20px;
}

.MuiButton-contained {
  box-shadow: none;
}

.arrow-icon {
  height: 6px;
}

img.encryption-icon {
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.MuiTypography-body1.encryption-text-small-warning {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #B45309;
}

.MuiTypography-body1.encryption-text-small-blocking {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #B91C1C;
}