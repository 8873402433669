.MuiTypography-body1.mfa-header {
    color: #101319;
    font-size: 24px !important;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 16px;
}

.MuiTypography-body1.mfa-header-h2 {
    color: #101319;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 16px;
}

.MuiTypography-body1.second-title {
    margin-top: 32px;
}

.mfa-buttons, .mfa-header-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-top: 16px;
    gap: 8px;
}

.mfa-content {
    margin-bottom: 32px;
    max-width: 500px;
}

.email-button {
    width: 360px !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.emailIcon {
    height: 18px;
    width: 18px;
}