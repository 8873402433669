.MuiButton-textSizeMedium.notNowButton {
    background-color: var(--color-gray-200);
    border-radius: 6px !important;
    padding: 12px 37px 12px 37px;
    text-transform: none;
    box-shadow: none;
    font-size: 14px;
    color: #000;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.MuiButton-textSizeMedium.notNowButton:hover {
    background-color: var(--color-gray-300);
}

.notNowButtonContainer {
    position: relative;
    display: inline-block;
    justify-content: center;
    margin-top: 8px;
}


.warningMessage {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
    transition: visibility 0.2s, opacity 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: var(--color-gray-100);
    border-radius: 6px !important;
    border: 1px solid var(--color-gray-200);
    color: #333;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: 8px;
    white-space: nowrap;
}

.warningMessage.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
}

.warningMessage span {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.warningIcon {
    width: 16px;
    height: 16px;
}