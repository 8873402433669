.top-bar {
    height: 105px;
    background-color: var(--color-gray-100);
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-height: 700px) {
    .top-bar {
        height: 70px;
    }
}

.top-bar .bar-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-bar .bar-content .signing-into {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.02em;
    text-align: center;
}

.top-bar .logo {
    margin-left: 10px;
    height: 30px;
}