.contentDecisions {
  padding-top: 24px;
}

.decisions-button-container {
  margin-top: 50px;
}

.decisions-button-container-mobile {
  display: none;
}

.MuiButton-root.button-refresh {
  width: max-content;
  min-width: 200px;
  height: 43px;
  border-radius: 6px;
  text-transform: inherit !important;
  margin-left: 8px;
  box-shadow: none;
  margin-top: -5px;
}

.decisions-button-container .notNowButtonContainer {
  margin-top: 0px;
}

.MuiButton-textSizeMedium.notNowButton {
  height: 43px;
  box-shadow: none;
  width: max-content;
  min-width: 200px;
}

@media only screen and (max-width: 600px) {
  .decisions-button-container {
    display: none;
  }

  .decisions-button-container-mobile {
    display: inherit;
    justify-content: center;
    margin-top: 50px;
  }

  .MuiButton-root.button-refresh {
    margin-left: 0px;
    margin-top: 0px;
    width: 90vw;
  }

  .MuiButton-textSizeMedium.notNowButton {
    width: 90vw;
  }
}
