:root {
  --footer-color: var(--color-gray-600);
  --icon-height: 35px;
  --language-button-bg: var(--color-gray-100);
  --language-button-border: 1px solid var(--color-gray-200);
  --language-button-radius: 4px;
  --language-menu-bg: var(--color-gray-100);
  --language-menu-border: 1px solid var(--color-gray-200);
  --language-menu-radius: 6px;
  --language-menu-hover-bg: var(--color-gray-200);
  --font-size: 12px;
  --font-weight: 500;
  --transition-duration: 0.3s;
  --menu-width: 120px;
}

.root {
  flex-grow: 1;
}

html,
body {
  overscroll-behavior-x: none;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
}

.footer {
  position: fixed;
  left: 32px;
  bottom: 24px;
  color: var(--footer-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.languages {
  display: flex;
  width: 100%;
  align-items: center;
}

.XFAIcon {
  height: var(--icon-height);
  width: auto;
  padding: 5px;
}

.languages {
  width: 100%;
  justify-content: right;
}

.languages .MuiButton-root.languageButton {
  background-color: var(--language-button-bg);
  color: black;
  border: var(--language-button-border);
  border-radius: var(--language-menu-radius);
  padding: 8px 16px;
  text-transform: capitalize;
  margin-right: 8px;
  width: var(--menu-width);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  min-width: 0 !important;
}

.languages .MuiButton-root.languageButton.active {
  background-color: var(--language-button-bg);
}

.XFAIcon {
  height: 35px;
  width: auto;
  padding: 5px;
}

.MuiPaper-root.MuiMenu-paper {
  box-shadow: none;
}

ul.MuiList-root.MuiMenu-list {
  width: var(--menu-width);
  border: var(--language-menu-border);
  border-radius: var(--language-menu-radius);
  background-color: var(--language-menu-bg);
  padding: 0;
  margin-bottom: 4px;
}

li.MuiMenuItem-root.languageMenuItem {
  padding: 8px 16px;
  height: 32px;
  line-height: 24px;
  text-align: left;
  text-transform: capitalize;
  width: var(--menu-width);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

li.MuiMenuItem-root.languageMenuItem:hover {
  background-color: var(--language-menu-hover-bg);
}

.icon,
.rotateIcon {
  transition: transform var(--transition-duration);
}

.rotateIcon {
  transform: rotate(180deg);
}